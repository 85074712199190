@use "./colors" as c;
@use "./sizes" as s;

form {
  label,
  input,
  button {
    font-size: inherit;
    font-family: inherit;
  }
}

.form {
  &-add,
  &-update,
  &-search {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;

    * {
      display: block;
    }

    &__input {
      width: 100%;
      padding: 0 1rem;
      outline: none;
      border: none;
      border-radius: s.$br-5 0 0 s.$br-5;
    }

    &__btn {
      background-color: c.$blue-1;
      border: none;
      border-radius: 0 s.$br-5 s.$br-5 0;
      cursor: pointer;
      font-weight: 600;
      outline: none;
      padding: 1rem;
      text-transform: uppercase;
      width: 8rem;

      &:hover,
      &:focus {
        background-color: c.$blue-2;
      }
    }
  }

  &-update {
    &__btn {
      background-color: c.$yellow-1;
      color: c.$brown-2;

      &:hover,
      &:focus {
        background-color: c.$yellow-2;
      }
    }
  }
}

.form-login {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  color: c.$brown-2;

  &__label {
    margin-bottom: 0.5rem;
    padding-left: 1rem;
    margin-top: 1rem;
  }

  &__input {
    border-radius: s.$br-5;
    padding: 1rem;
    margin-bottom: 0.2rem;
    border: none;
  }

  &__btn {
    padding: 1rem;
    border: none;
    margin-top: 2rem;
    border-radius: s.$br-5;
    font-weight: bold;
    font-size: inherit;
    background-color: c.$yellow-1;
    color: c.$brown-2;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: c.$yellow-2;
    }
  }
}

.form-search {
  position: relative;

  &__input {
    height: 3.6rem;
    border-radius: s.$br-5;
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
    font-size: 2.6rem;
    color: c.$gray-4;
  }
}

.field-error {
  background-color: c.$red-1;
  color: c.$red-5;
  padding: 0.5rem 1rem;
  border-radius: s.$br-5;
  font-size: 1.4rem;
}
