@use "./colors" as c;

$footerTitleHeight: 3rem;
$footerNavHeight: 6rem;

.footer {
  position: fixed;
  bottom: calc(-1 * $footerNavHeight);
  left: 0;
  width: 100%;
  background-color: c.$yellow-1;
  color: c.$brown-2;
  border-top: 2px solid c.$brown-2;
  letter-spacing: 0.1rem;
  transition: bottom 0.15s ease-in-out;

  &.opened {
    bottom: 0;
  }

  &__blog {
    margin-left: 1rem;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid c.$brown-1;
    height: $footerTitleHeight;
    position: relative;

    &__btn {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      cursor: pointer;
    }
  }

  &__nav {
    align-items: center;
    background-color: c.$yellow-1;
    color: c.$brown-2;
    display: flex;
    height: $footerNavHeight;
    justify-content: center;
    padding: 0 1rem;
    width: 100%;

    a {
      &:hover {
        color: c.$brown-4;
        text-decoration: underline;
      }
    }
  }
}
