@use "./sizes" as s;
@use "./colors" as c;
@use "./delete_confirm";
@use "./demo";
@use "./forms";
@use "./navbar";
@use "./notifications";
@use "./task-item";
@use "./footer";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

input {
  background-color: #eee;
}

input,
button {
  &:focus {
    background-color: c.$yellow-1;
    border: 2px solid c.$brown-2;
    outline: none;
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  background-color: c.$white;
  font-size: 1.6rem;
}

#__page {
  background: rgb(141, 184, 86);
  background: linear-gradient(90deg, #8db856 0%, #4ca67c 100%);
  min-height: 100vh;
  padding-bottom: 3rem;
  position: relative;
}

.kontainer {
  max-width: 620px;
  margin: 0 auto;
  padding: 0 5px;
  min-height: 92vh;
}

.heading-h1 {
  text-align: center;
  margin-top: 1rem;
  color: c.$brown-2;
}

.tasks-kontainer {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.task-list {
  border-radius: s.$br-5;
}

.welcome-msg {
  padding: 2rem;
  margin-top: 2rem;
  background-color: c.$yellow-1;
  border-radius: s.$br-5;
  border: 1px solid c.$brown-2;
  color: c.$brown-2;
  text-align: center;
}

.menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #d9f0c6;
  }

  &__text {
    margin-left: 1rem;
  }

  &.danger {
    background-color: c.$red-1;
    color: c.$red-5;

    &:hover {
      background-color: c.$red-2;
    }
  }
}

.text {
  &-danger {
    color: #b82d2d;
  }
}

.no-tasks {
  background-color: #feeacd;
  color: #7d4c03;
  text-align: center;
  padding: 2rem;
  border: 1px solid #7d4c03;
  border-radius: s.$br-5;
}

.login-btn {
  display: block;
  padding: 0 2rem;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    background-color: c.$yellow-3;
  }
}

.logout-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: block;
  padding: 0 2rem;

  &:hover,
  &:focus {
    background-color: c.$yellow-3;
  }
}

.foot-nav {
  align-items: center;
  background-color: c.$yellow-1;
  color: c.$brown-2;
  display: flex;
  height: 6rem;
  justify-content: center;
  padding: 0 1rem;
  width: 100%;

  a {
    &:hover {
      color: c.$brown-4;
      text-decoration: underline;
    }
  }
}

.page-404 {
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #999;
  background: linear-gradient(90deg, #9cb47b 0%, #75af94 100%);
  padding-top: 4rem;
  color: #333;
  font-family: cursive;

  &__num {
    font-size: 8rem;
  }

  &__pnf {
    font-size: 3rem;
  }
}
