$white: #fff;
$yellow-1: #fff0a7;
$yellow-2: #ffea80;
$yellow-3: #ffe358;
$yellow-4: #ffdd31;
$yellow-5: #ffd60a;
$blue-1: #d4e9f7;
$blue-2: #adc6eb;
$blue-3: #85a9e0;
$blue-4: #5c8dd6;
$blue-5: #11405f;
$green-1: #dbf5d6;
$green-2: #caf0c2;
$green-3: #a6e699;
$green-4: #82db70;
$green-5: #3f4e0e;
$red-1: #f5d8d6;
$red-2: #edb9b6;
$red-3: #e49a95;
$red-4: #dc7b74;
$red-5: #9b2e27;
$gray-1: #cccccc;
$gray-2: #b8b8b8;
$gray-3: #a3a3a3;
$gray-4: #8a8a8a;
$gray-5: #505050;
$brown-1: #5f440d;
$brown-2: #553b04;
$brown-3: #463004;
$brown-4: #473000;
$brown-5: #332200;
