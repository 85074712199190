.delete-confirm {
  color: #473000;
  background: linear-gradient(90deg, #f4eb97, #f1a2a2);
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 620px;
  height: 50%;
  display: flex;
}

.delete-confirm--base {
  z-index: 30;
  background-color: #000000b8;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.delete-confirm__text {
  margin-bottom: 4rem;
  font-size: 3rem;
  display: block;
}

.delete-confirm__btn--yes, .delete-confirm__btn--no {
  cursor: pointer;
  border: 2px solid;
  border-radius: 5px;
  padding: 1rem 4rem;
  font-weight: bold;
}

.delete-confirm__btn--yes {
  color: #9b2e27;
  background-color: #f5d8d6;
  border-color: #9b2e27;
  margin-bottom: 2rem;
}

.delete-confirm__btn--yes:hover {
  background-color: #edb9b6;
}

.delete-confirm__btn--no {
  color: #505050;
  background-color: #ccc;
  border-color: #505050;
}

.delete-confirm__btn--no:hover {
  color: #11405f;
  background-color: #adc6eb;
}

body {
  color: #333;
}

form label, form input, form button {
  font-size: inherit;
  font-family: inherit;
}

.form-add, .form-update, .form-search {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
}

.form-add *, .form-update *, .form-search * {
  display: block;
}

.form-add__input, .form-update__input, .form-search__input {
  border: none;
  border-radius: 5px 0 0 5px;
  outline: none;
  width: 100%;
  padding: 0 1rem;
}

.form-add__btn, .form-update__btn, .form-search__btn {
  cursor: pointer;
  text-transform: uppercase;
  background-color: #d4e9f7;
  border: none;
  border-radius: 0 5px 5px 0;
  outline: none;
  width: 8rem;
  padding: 1rem;
  font-weight: 600;
}

.form-add__btn:hover, .form-add__btn:focus, .form-update__btn:hover, .form-update__btn:focus, .form-search__btn:hover, .form-search__btn:focus {
  background-color: #adc6eb;
}

.form-update__btn {
  color: #553b04;
  background-color: #fff0a7;
}

.form-update__btn:hover, .form-update__btn:focus {
  background-color: #ffea80;
}

.form-login {
  color: #553b04;
  flex-direction: column;
  margin-top: 2rem;
  display: flex;
}

.form-login__label {
  margin-top: 1rem;
  margin-bottom: .5rem;
  padding-left: 1rem;
}

.form-login__input {
  border: none;
  border-radius: 5px;
  margin-bottom: .2rem;
  padding: 1rem;
}

.form-login__btn {
  font-weight: bold;
  font-size: inherit;
  color: #553b04;
  cursor: pointer;
  background-color: #fff0a7;
  border: none;
  border-radius: 5px;
  margin-top: 2rem;
  padding: 1rem;
}

.form-login__btn:hover, .form-login__btn:focus {
  background-color: #ffea80;
}

.form-search {
  position: relative;
}

.form-search__input {
  border-radius: 5px;
  height: 3.6rem;
}

.form-search__icon {
  color: #8a8a8a;
  align-items: center;
  height: 100%;
  padding-right: .5rem;
  font-size: 2.6rem;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.field-error {
  color: #9b2e27;
  background-color: #f5d8d6;
  border-radius: 5px;
  padding: .5rem 1rem;
  font-size: 1.4rem;
}

.navbar {
  color: #553b04;
  z-index: 15;
  background-color: #fff0a7;
  border-bottom: 2px solid #553b04;
  justify-content: space-between;
  align-items: stretch;
  height: 4rem;
  display: flex;
  position: sticky;
  top: 0;
}

.navbar__brand {
  align-items: center;
  padding: 0 1rem;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
}

.navbar__right {
  display: flex;
}

.navbar__username {
  align-items: center;
  font-size: 1.4rem;
  display: flex;
}

@media screen and (width >= 500px) {
  .navbar__username {
    font-size: 1.6rem;
  }
}

.notification {
  z-index: 20;
  background-color: #fff0a7;
  border: 2px solid;
  border-radius: 1rem;
  width: 310px;
  height: auto;
  padding: 2rem 1.6rem;
  font-weight: bold;
  transition: left .5s ease-in-out;
  position: fixed;
  top: 2rem;
  left: 105%;
}

.notification[data-level="danger"] {
  color: #9b2e27;
  background-color: #f5d8d6;
  border-color: #9b2e27;
}

.notification[data-level="success"] {
  color: #3f4e0e;
  background-color: #dbf5d6;
  border-color: #3f4e0e;
}

.notification[data-level="warning"] {
  color: #553b04;
  background-color: #fff0a7;
  border-color: #553b04;
}

.notification[data-level="info"] {
  color: #11405f;
  background-color: #d4e9f7;
  border-color: #11405f;
}

.noti-visible {
  left: calc(100% - 311px);
}

.task-item {
  background-color: #ffe358;
  border-bottom: 1px solid #bbb;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.task-item:first-child {
  border-radius: 5px 5px 0 0;
}

.task-item:last-child {
  border-radius: 0 0 5px 5px;
}

.task-item.done {
  background-color: #fff0a7;
}

.task-item.done .task-item__text {
  text-decoration: line-through;
}

.task-item__text {
  align-self: stretch;
  width: 100%;
  padding: 1.2rem;
}

.task-item__btn {
  cursor: pointer;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 1rem;
  display: flex;
}

.task-item__btn:hover {
  background-color: #fff0a7;
}

.task-item__menu {
  z-index: 10;
  background-color: #fdfdfd;
  border: 1px solid #eee;
  border-radius: 1rem;
  min-width: 180px;
  display: none;
  position: absolute;
  top: 96%;
  right: 0;
  overflow: hidden;
}

.task-item__menu.visible {
  display: block;
}

.footer {
  color: #553b04;
  letter-spacing: .1rem;
  background-color: #fff0a7;
  border-top: 2px solid #553b04;
  width: 100%;
  transition: bottom .15s ease-in-out;
  position: fixed;
  bottom: -6rem;
  left: 0;
}

.footer.opened {
  bottom: 0;
}

.footer__blog {
  margin-left: 1rem;
}

.footer__title {
  border-bottom: 1px solid #5f440d;
  justify-content: center;
  align-items: center;
  height: 3rem;
  display: flex;
  position: relative;
}

.footer__title__btn {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.footer__nav {
  color: #553b04;
  background-color: #fff0a7;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6rem;
  padding: 0 1rem;
  display: flex;
}

.footer__nav a:hover {
  color: #473000;
  text-decoration: underline;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

input {
  background-color: #eee;
}

input:focus, button:focus {
  background-color: #fff0a7;
  border: 2px solid #553b04;
  outline: none;
}

body {
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 1.6rem;
}

#__page {
  background: linear-gradient(90deg, #8db856 0%, #4ca67c 100%);
  min-height: 100vh;
  padding-bottom: 3rem;
  position: relative;
}

.kontainer {
  max-width: 620px;
  min-height: 92vh;
  margin: 0 auto;
  padding: 0 5px;
}

.heading-h1 {
  text-align: center;
  color: #553b04;
  margin-top: 1rem;
}

.tasks-kontainer {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.task-list {
  border-radius: 5px;
}

.welcome-msg {
  color: #553b04;
  text-align: center;
  background-color: #fff0a7;
  border: 1px solid #553b04;
  border-radius: 5px;
  margin-top: 2rem;
  padding: 2rem;
}

.menu-item {
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.menu-item:hover {
  background-color: #d9f0c6;
}

.menu-item__text {
  margin-left: 1rem;
}

.menu-item.danger {
  color: #9b2e27;
  background-color: #f5d8d6;
}

.menu-item.danger:hover {
  background-color: #edb9b6;
}

.text-danger {
  color: #b82d2d;
}

.no-tasks {
  color: #7d4c03;
  text-align: center;
  background-color: #feeacd;
  border: 1px solid #7d4c03;
  border-radius: 5px;
  padding: 2rem;
}

.login-btn {
  align-items: center;
  padding: 0 2rem;
  display: flex;
}

.login-btn:hover, .login-btn:focus {
  background-color: #ffe358;
}

.logout-btn {
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: 0 2rem;
  display: block;
}

.logout-btn:hover, .logout-btn:focus {
  background-color: #ffe358;
}

.foot-nav {
  color: #553b04;
  background-color: #fff0a7;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6rem;
  padding: 0 1rem;
  display: flex;
}

.foot-nav a:hover {
  color: #473000;
  text-decoration: underline;
}

.page-404 {
  color: #333;
  background: linear-gradient(90deg, #9cb47b 0%, #75af94 100%);
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 4rem;
  font-family: cursive;
  display: flex;
}

.page-404__num {
  font-size: 8rem;
}

.page-404__pnf {
  font-size: 3rem;
}

/*# sourceMappingURL=index.2f3f422f.css.map */
