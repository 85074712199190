@use "./colors" as c;

.navbar {
  align-items: stretch;
  background-color: c.$yellow-1;
  border-bottom: 2px solid c.$brown-2;
  // box-shadow: 0 -2px 1rem gray;
  color: c.$brown-2;
  display: flex;
  height: 4rem;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 15;

  &__brand {
    font-size: 2rem;
    font-weight: bold;
    padding: 0 1rem;
    display: flex;
    align-items: center;
  }

  &__right {
    display: flex;
  }

  &__username {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 500px) {
  .navbar {
    &__username {
      font-size: 1.6rem;
    }
  }
}
