@use "./colors" as c;
@use "./sizes" as s;

$itemMenuWidth: 180px;

.task-item {
  align-items: center;
  background-color: c.$yellow-3;
  border-bottom: 1px solid #bbb;
  display: flex;
  justify-content: space-between;
  position: relative;

  &:first-child {
    border-radius: s.$br-5 s.$br-5 0 0;
  }

  &:last-child {
    border-radius: 0 0 s.$br-5 s.$br-5;
  }

  &.done {
    background-color: c.$yellow-1;
    .task-item__text {
      text-decoration: line-through;
    }
  }

  &__text {
    width: 100%;
    align-self: stretch;
    padding: 1.2rem;
  }

  &__btn {
    $size: 3.2rem;
    width: $size;
    height: $size;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    cursor: pointer;

    &:hover {
      background-color: c.$yellow-1;
    }
  }

  &__menu {
    background-color: #fdfdfd;
    border: 1px solid #eee;
    border-radius: 1rem;
    overflow: hidden;
    display: none;
    min-width: $itemMenuWidth;
    position: absolute;
    right: 0;
    top: 96%;
    z-index: 10;

    &.visible {
      display: block;
    }
  }
}
