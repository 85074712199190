@use "./colors" as c;

$noti-width: 310px;

.notification {
  background-color: c.$yellow-1;
  border: 2px solid;
  border-radius: 1rem;
  // min-height: 100px;
  height: auto;
  padding: 2rem 1.6rem;
  position: fixed;
  left: 105%;
  top: 2rem;
  transition: left 0.5s ease-in-out;
  width: $noti-width;
  z-index: 20;
  font-weight: bold;

  &[data-level="danger"] {
    background-color: c.$red-1;
    color: c.$red-5;
    border-color: c.$red-5;
  }

  &[data-level="success"] {
    background-color: c.$green-1;
    color: c.$green-5;
    border-color: c.$green-5;
  }

  &[data-level="warning"] {
    background-color: c.$yellow-1;
    color: c.$brown-2;
    border-color: c.$brown-2;
  }

  &[data-level="info"] {
    background-color: c.$blue-1;
    color: c.$blue-5;
    border-color: c.$blue-5;
  }
}

.noti-visible {
  left: calc(100% - ($noti-width + 1px));
}
