@use "./colors" as c;
@use "./sizes" as s;

.delete-confirm {
  &--base {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.72);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 30;
  }

  align-items: center;
  background-color: #f7d28f;
  background: linear-gradient(90deg, #f4eb97, #f1a2a2);
  color: c.$brown-4;
  border-radius: s.$br-5;
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content: center;
  max-width: 620px;
  width: 100%;

  &__text {
    display: block;
    font-size: 3rem;
    margin-bottom: 4rem;
  }

  &__btn--yes,
  &__btn--no {
    cursor: pointer;
    padding: 1rem 4rem;
    border: 2px solid;
    border-radius: s.$br-5;
    font-weight: bold;
  }

  &__btn--yes {
    background-color: c.$red-1;
    border-color: c.$red-5;
    color: c.$red-5;
    margin-bottom: 2rem;

    &:hover {
      background-color: c.$red-2;
    }
  }

  &__btn--no {
    background-color: c.$gray-1;
    border-color: c.$gray-5;
    color: c.$gray-5;

    &:hover {
      background-color: c.$blue-2;
      color: c.$blue-5;
    }
  }
}
